import { AiFillQuestionCircle } from 'react-icons/ai'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { Trans } from 'next-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'

const SidebarFooter = () => {
    const { pathname } = useRouter()
    const isActiveLink = (path: string) => {
        if (!path) {
            return false
        }
        return pathname === path || pathname.startsWith(`${path}/`)
    }
    return (
        <div className='flex flex-col flex-shrink-0 pt-7 border-t-2 mx-6 gap-4'>
            <Link
                className={`item group title flex gap-2 cursor-pointer text-gray-500 !m-0 flex-1 ${
                    isActiveLink('/app/ideas') ? 'active' : 'inactive'
                }`}
                href='/app/ideas'
            >
                <HiOutlineLightBulb className='w-5 h-5' />
                <Trans i18nKey='ideas.title' />
            </Link>
            <Link
                className={`item group title flex gap-2 cursor-pointer text-gray-500 !m-0 flex-1 ${
                    isActiveLink('/app/help') ? 'active' : 'inactive'
                }`}
                href='/app/help'
            >
                <AiFillQuestionCircle className='w-5 h-5' />
                <Trans i18nKey='sidebar.items.help' />
            </Link>
        </div>
    )
}

export default SidebarFooter
