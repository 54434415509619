import { OrderStatus, PriceOffer } from '../../../services/api/order/OrderService.types'
import { ReactNode, isValidElement } from 'react'
import { Trans } from 'next-i18next'
import Badge from '../../base/badge/Badge'
import DemandIcon from '../../base/icon/DemandIcon'
import DemandStatusBadge from '../demand/detail/DemandStatusBadge'
import Link from 'next/link'
import Tooltip from '../../base/tooltip/Tooltip'

export type AppBarTitleProps = {
    title: string | ReactNode
    // hasDemandStatus?: boolean
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
}

const AppBarTitle = ({ title, demandStatus, priceOffer }: AppBarTitleProps) => {
    const renderPriceOffers = () => {
        return priceOffer.map(offer => {
            let displayText
            if (offer.name) {
                displayText = offer.name
            } else {
                displayText = offer.number
            }

            const tooltipContent = offer.name
                ? `${(<Trans i18nKey='dashboard.shared.appbar.demand_part_of_price_offer' />)} ${offer.name}`
                : `${(<Trans i18nKey='dashboard.shared.appbar.demand_part_of_price_offer' />)} ${offer.number}`

            return (
                <Link key={offer.id} href={`/app/price-offers/create/${offer.id}`} passHref>
                    <Tooltip content={tooltipContent} placement='bottom'>
                        <Badge color='green'>
                            <DemandIcon fill='green' className='!h-[16px] !w-[12px] !mr-1' />
                            {displayText}
                        </Badge>
                    </Tooltip>
                </Link>
            )
        })
    }

    if (typeof title === 'string' || isValidElement(title)) {
        return (
            <div className=' flex flex-row gap-2 items-center'>
                <h1 className='font-bold text-2xl leading-10 mr-2'>{title}</h1>
                {demandStatus && <DemandStatusBadge status={demandStatus} />}
                {priceOffer && renderPriceOffers()}
            </div>
        )
    }
    return <h1>{title}</h1>
}

export default AppBarTitle
