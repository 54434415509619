import { Node } from '../components/dashboard/administration/role/edit/AdministrationRoleEditList'
import { SidebarItem } from '../components/dashboard/sidebar/Sidebar'
import React from 'react'

export const buildHierarchy = (permissions: string[]): Node[] => {
    const root: Node[] = []
    permissions.forEach(permission => {
        const parts = permission.split('.')
        let currentLevel = root
        let fullPath = ''
        parts.forEach((part, index) => {
            if (fullPath) {
                fullPath += '.'
            }
            fullPath += part
            let existingNode = currentLevel.find(node => node.value === fullPath)
            if (!existingNode) {
                existingNode = { value: fullPath, label: <span>{part}</span> }
                if (index < parts.length - 1) {
                    existingNode.children = []
                }
                currentLevel.push(existingNode)
            }
            if (existingNode.children) {
                currentLevel = existingNode.children
            }
        })
    })
    return root
}

const extractPermissionPart = (permission: string): string | null => {
    const parts = permission.split('.')
    if (parts.length === 3) {
        return parts[1]
    }
    if (parts.length === 2) {
        return parts[0]
    }
    return null
}

export const filterNavigationItemsByPermissions = (
    rolePermissions: string[],
    navigationItems: SidebarItem[]
): SidebarItem[] => {
    const extractedPermissions = rolePermissions?.map(extractPermissionPart).filter(Boolean)
    return navigationItems
        .map(item => {
            if (item.navigation) {
                const filteredNavigation = item.navigation.filter(navItem => {
                    const navHrefPart = navItem.href?.split('/').pop()
                    const isCartageAndCustomerRoute =
                        navItem.href.includes('/cartages-and-customers') &&
                        !!rolePermissions?.find(rolePermission => rolePermission === 'cartage.list-customer')
                    return (
                        extractedPermissions?.includes(navHrefPart || '') ||
                        (navHrefPart === 'taker' && extractedPermissions?.includes('user')) ||
                        (navHrefPart === 'cartages-and-customers' && extractedPermissions?.includes('')) ||
                        isCartageAndCustomerRoute
                    )
                })
                if (!!filteredNavigation.length) {
                    return { ...item, navigation: filteredNavigation }
                }
            }
            return null
        })
        .filter(Boolean) as SidebarItem[]
}
