import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import {
    FocusEvent,
    ForwardedRef,
    InputHTMLAttributes,
    ReactNode,
    Ref,
    forwardRef,
    isValidElement,
    useId,
    useMemo,
    useState
} from 'react'
import { Label } from '../label/Label'
import { ThemeProps } from '../theme/ThemeProps'
import { XIcon } from '@heroicons/react/outline'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import Button from '../button/Button'
import Tooltip, { TooltipRef } from '../tooltip/Tooltip'

export type TextFieldType = 'text' | 'password' | 'email' | 'number' | 'date' | 'file'

type TextFieldSize = 'sm' | 'md' | 'lg'

export type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'size'> &
    ThemeProps & {
        label?: string
        i18nKey?: string
        subtitle?: string
        type?: TextFieldType
        valid?: boolean
        size?: TextFieldSize
        icon?: ReactNode
        rightIcon?: ReactNode
        message?: ReactNode
        renderMessageInTooltip?: boolean
        tooltipRef?: Ref<TooltipRef>
        removePaddingTop?: boolean
        srOnlyLabel?: boolean
        forceReadOnly?: boolean
        inputClassName?: string
        disableReadOnlyStyle?: boolean
        enableRightIconPointerEvents?: boolean
        enableLeftIconPointerEvents?: boolean
        enableOnFocusSelect?: boolean
        isRequired?: boolean
        leftText?: string | ReactNode
        isTable?: boolean
        showCross?: boolean
    }

const TextField = (
    {
        type = 'text',
        label,
        i18nKey,
        subtitle,
        valid = true,
        color = 'primary',
        size = 'md',
        className,
        inputClassName,
        icon,
        rightIcon,
        message,
        renderMessageInTooltip,
        removePaddingTop,
        srOnlyLabel,
        tooltipRef,
        forceReadOnly,
        disableReadOnlyStyle,
        enableRightIconPointerEvents,
        enableLeftIconPointerEvents,
        enableOnFocusSelect,
        isRequired = false,
        isTable = false,
        leftText,
        placeholder,
        showCross = false,
        ...props
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const randomId = useId()
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const renderClearButton = () => {
        if (showCross && props.value) {
            return (
                <Button
                    className='absolute -right-2 top-[1px] text-gray-650'
                    icon
                    variant='unstyled'
                    onClick={() => props.onChange?.({ target: { value: '' } } as any)}
                >
                    <XIcon />
                </Button>
            )
        }
        return null
    }

    if (isTable) {
        placeholder = '...'
    }

    const renderTogglePassword = () => {
        if (type === 'password') {
            return (
                <Button
                    variant='unstyled'
                    className='absolute right-1 top-0 '
                    icon
                    size='icon'
                    onClick={() => {
                        return setShowPassword(prev => {
                            return !prev
                        })
                    }}
                >
                    {showPassword ? (
                        <EyeIcon fill='text-gray-600' className='h-5 w-5' />
                    ) : (
                        <EyeOffIcon fill='text-gray-600' className='h-5 w-5' />
                    )}
                </Button>
            )
        }
    }

    const inputType = useMemo<TextFieldType>(() => {
        if (type === 'password') {
            return showPassword ? 'text' : 'password'
        }
        return type
    }, [showPassword, type])

    const renderIcon = () => {
        return (
            icon && (
                <span
                    className={buildClassesWithDefault(
                        {
                            'pointer-events-none': !enableLeftIconPointerEvents
                        },
                        'left-icon'
                    )}
                >
                    {icon}
                </span>
            )
        )
    }

    const renderRightIcon = () => {
        return (
            rightIcon && (
                <span
                    className={buildClassesWithDefault(
                        {
                            'pointer-events-none': !enableRightIconPointerEvents
                        },
                        'right-icon'
                    )}
                >
                    {rightIcon}
                </span>
            )
        )
    }

    const renderMessage = () => {
        return message && <span className='text-field-message'>{message}</span>
    }

    const containerClassName = buildClassesWithDefault(
        {
            invalid: !valid,
            [color]: true,
            [size]: true,
            'text-field': true,
            'font-medium': isTable,
            icon: !!icon,
            '!pt-0': removePaddingTop,
            'has-right-icon': !!rightIcon,
            'disable-read-only-style': disableReadOnlyStyle,
            'table-input': isTable
        },
        className,
        `flex flex-col ${srOnlyLabel ? '' : 'gap-2'}`
    )

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
        if (enableOnFocusSelect) {
            event.target.select()
        }
        props?.onFocus?.(event)
    }

    const renderInput = (additionalRef?: Ref<HTMLInputElement>, additionalProps: Record<string, unknown> = {}) => {
        return (
            <>
                <div {...additionalProps} ref={additionalRef} className='relative'>
                    <input
                        {...props}
                        className={inputClassName}
                        type={inputType}
                        ref={ref}
                        id={randomId}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        autoComplete={props.autoComplete || 'off'}
                        readOnly={forceReadOnly || props.readOnly}
                        data-test-element-type={type}
                        data-test-name={props.name}
                        data-test-element='input'
                    />
                    {renderIcon()}
                    {renderRightIcon()}
                    {renderTogglePassword()}
                    {renderClearButton()}
                </div>
                {!renderMessageInTooltip && renderMessage()}
            </>
        )
    }

    return (
        <div className={containerClassName}>
            {(!!label?.length || !!i18nKey?.length || isValidElement(leftText)) && (
                <div className='flex flex-row'>
                    {leftText && <div className='pr-2'>{leftText}</div>}
                    <Label
                        text={label}
                        i18nKey={i18nKey}
                        isRequired={isRequired}
                        htmlFor={randomId}
                        subtitle={subtitle}
                        srOnly={srOnlyLabel}
                        placeholder={placeholder}
                    />
                </div>
            )}
            <div className='relative'>
                <div className='flex flex-col'>
                    {renderMessageInTooltip ? (
                        <Tooltip
                            content={message}
                            backgroundColor={!valid ? 'danger.DEFAULT' : 'primary.DEFAULT'}
                            textColor='white'
                            ref={tooltipRef}
                            disabled={!renderMessageInTooltip || !message}
                            lazy={false}
                        >
                            {renderInput}
                        </Tooltip>
                    ) : (
                        renderInput()
                    )}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(TextField)
