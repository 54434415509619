import { ButtonSize, ButtonType, ButtonVariant, buttonConfig } from './Button.config'
import { ElementType, ForwardedRef, MouseEvent, ReactNode, forwardRef } from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { removeUndefinedValuesFromObject } from '../../../utils/ObjectHelper'
import Loading from '../loading/Loading'

const defaultElementType = 'button'

type BaseButtonProps = {
    variant?: ButtonVariant
    styleType?: ButtonType
    size?: ButtonSize
    leftIcon?: ReactNode
    rightIcon?: ReactNode
    icon?: boolean
    loading?: boolean
    stopPropagation?: boolean
    isCardOpen?: boolean
}

export type ButtonProps<El extends ElementType = typeof defaultElementType> = PolymorphicPropsWithoutRef<
    BaseButtonProps,
    El
>

const Button = <El extends ElementType = typeof defaultElementType>(
    {
        as,
        children,
        styleType = 'normal',
        variant = 'primary',
        className: classNameProp,
        size = 'md',
        leftIcon,
        rightIcon,
        icon = false,
        loading = false,
        onClick,
        stopPropagation = true,
        ...props
    }: ButtonProps<El>,
    ref: ForwardedRef<HTMLElement>
) => {
    const Element: ElementType = as || defaultElementType
    const isDisabled = (props as unknown as Record<string, unknown>)?.disabled || loading
    const type =
        Element.toString() === 'button' ? (props as unknown as Record<string, unknown>)?.type || 'button' : undefined
    const isShadowButton =
        variant === 'tertiaryV2' ||
        variant === 'primary' ||
        variant === 'primary-small' ||
        variant === 'secondary' ||
        variant === 'secondary-small'

    const className = buildClassesWithDefault(
        {
            button: true,
            'only-icon': icon,
            [`variant-${styleType}`]: true,
            [buttonConfig.sizes.button[size]]: true,
            [buttonConfig.variant[isDisabled ? 'disabled' : variant][styleType]]: true
        },
        classNameProp,
        isShadowButton ? '' : 'px-2.5! pt-2.5!'
    )

    const iconClassName = buildClassesWithDefault(
        {
            [buttonConfig.sizes.icon[size]]: true
        },
        'icon'
    )

    const click = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (stopPropagation) {
            event.stopPropagation()
        }
        return (!loading || !isDisabled) && onClick && onClick(event)
    }

    const wrapIcon = (icon: ReactNode, additionalClassname?: string) => {
        if (!icon) {
            return
        }
        return <span className={buildClassesWithDefault(iconClassName, additionalClassname)}>{icon}</span>
    }

    const renderLeftIconOrLoading = () => {
        if (loading) {
            return wrapIcon(
                <Loading
                    className={buildClassesWithDefault(
                        buttonConfig.variant[variant]['loadingIconColor'],
                        '!w-full !h-full'
                    )}
                    color='custom'
                />,
                icon ? '' : 'left'
            )
        }

        return wrapIcon(leftIcon, 'left')
    }

    const renderChildren = () => {
        if (icon && loading) {
            return
        }
        return icon ? wrapIcon(children) : children
    }

    return (
        <Element
            {...{ ...props, ...removeUndefinedValuesFromObject({ type }) }}
            ref={ref}
            onClick={click}
            className={className}
            disabled={isDisabled}
        >
            {renderLeftIconOrLoading()}
            {renderChildren()}
            {wrapIcon(rightIcon, 'right')}
        </Element>
    )
}

export default forwardRef(Button)
