import { PropsWithChildren, ReactNode, isValidElement } from 'react'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import useTheme from '../../hooks/useTheme'

export type LayoutProps = {
    appendHeaders?: JSX.Element
    title: string | ReactNode
    description?: string
    keywords?: string[]
}

const getTitle = (title: string | ReactNode, projectName: string, t: (key: string) => string): string => {
    if (typeof title === 'string') {
        return `${title} | ${projectName}`
    } else if (isValidElement<{ i18nKey?: string; children?: ReactNode }>(title)) {
        if (title.props.i18nKey) {
            return `${t(title.props.i18nKey)} | ${projectName}`
        }
        if (title.props.children) {
            if (Array.isArray(title.props.children) && title.props.children.length > 0) {
                const concatenatedTitle = title.props.children
                    .map(child => {
                        if (child?.props?.i18nKey) {
                            return t(child.props.i18nKey)
                        }
                        if (typeof child === 'string') {
                            return child
                        }
                        return ''
                    })
                    .join(' ')

                return `${concatenatedTitle} | ${projectName}`
            }
            const children = title.props.children
            const firstChild = Array.isArray(children) ? children[0] : children
            return `${firstChild} | ${projectName}`
        }
    }

    return projectName
}

const Layout = ({
    children,
    appendHeaders,
    title,
    description = '',
    keywords = []
}: PropsWithChildren<LayoutProps>) => {
    const { t } = useTranslation()
    const { projectName, primaryColor, iconBackgroundColor, getPageFavicon } = useTheme()
    const pageKeywords = keywords?.join(',') || ''
    const pageDescription = description || ''
    const pageFavicon = getPageFavicon('favicon.ico')
    const pageFavicon32x32 = getPageFavicon('favicon-32x32.png')
    const pageFavicon16x16 = getPageFavicon('favicon-16x16.png')
    const pageAppleTouchIcon = getPageFavicon('apple-touch-icon.png')
    const pageWebManifestFile = getPageFavicon('site.webmanifest')
    const pageSafariPinnedTab = getPageFavicon('safari-pinned-tab.svg')

    return (
        <>
            <Head>
                <meta name='viewport' content='initial-scale=1.0, width=device-width' />

                <title>{getTitle(title, projectName, t)}</title>
                <meta name='description' content={description} />
                <meta name='keywords' content={pageKeywords} />

                <meta property='og:title' content={getTitle(title, projectName, t)} />
                <meta property='og:description' content={pageDescription} />
                <meta property='og:type' content='website' />

                <link rel='shortcut icon' type='image/jpg' href={pageFavicon} />
                <link rel='apple-touch-icon' sizes='180x180' href={pageAppleTouchIcon} />
                <link rel='icon' type='image/png' sizes='32x32' href={pageFavicon32x32} />
                <link rel='icon' type='image/png' sizes='16x16' href={pageFavicon16x16} />
                <link rel='manifest' href={pageWebManifestFile} />
                <link rel='mask-icon' href={pageSafariPinnedTab} color={primaryColor} />
                <meta name='msapplication-TileColor' content={iconBackgroundColor} />
                <meta name='theme-color' content={iconBackgroundColor} />
                {appendHeaders}
            </Head>
            <main className='w-full h-full'>{children}</main>
        </>
    )
}

export default Layout
